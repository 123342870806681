import React from "react"
import Moment from "react-moment"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import colors from "styles/colors"
import PropTypes from "prop-types"
import dimensions from "styles/dimensions"

const PostRowContainer = styled(Link)`
  padding: 1em 2.5em 0.5em 2.5em;
  text-decoration: none;
  color: currentColor;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 150ms ease-in-out;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    padding: 1em 0em 0.5em 0em;
    flex-wrap: wrap;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    padding: 1em 0em 0.5em 0em;
    flex-wrap: wrap;
  }

  &:hover {
    cursor: pointer;

    transition: all 100ms ease-in-out;

    &:nth-of-type(1) {
      color: ${colors.blue600};
      background-color: ${colors.blue200};
    }
    &:nth-of-type(2) {
      color: ${colors.orange600};
      background-color: ${colors.orange200};
    }
    &:nth-of-type(3) {
      color: ${colors.purple600};
      background-color: ${colors.purple200};
    }
    &:nth-of-type(4) {
      color: ${colors.green600};
      background-color: ${colors.green200};
    }
    &:nth-of-type(5) {
      color: ${colors.teal600};
      background-color: ${colors.teal200};
    }

    .PostRowAction {
      color: ${colors.blue500};
      transition: all 150ms ease-in-out;

      span {
        transform: translateX(0px);
        opacity: 1;
        transition: transform 150ms ease-in-out;
      }
    }
  }
`

const PostTitle = styled("h3")`
  margin: 0;
  margin-right: 0.5em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    width: 100%;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    width: 100%;
  }
`

const PostMetas = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.85em;
  text-align: right;
  color: ${colors.grey600};

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    text-align: left;
    padding-top: 0.5em;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    text-align: left;
    padding-top: 0.5em;
  }
`

const PostDate = styled("div")`
  margin: 0;
`

const PostRow = ({ author, category, date, title, description, uid }) => (
  <PostRowContainer className="BlogPostRow" to={`/blog/${uid}`}>
    <PostTitle>{title[0].text}</PostTitle>
    <PostMetas>
      <PostDate>
        <Moment format="DD-MM-YYYY">{date}</Moment>
      </PostDate>
    </PostMetas>
  </PostRowContainer>
)

export default PostRow

PostRow.propTypes = {
  author: PropTypes.string.isRequired,
  category: PropTypes.array.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.array.isRequired,
  description: PropTypes.array.isRequired,
  uid: PropTypes.string.isRequired,
}
